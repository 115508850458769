import { useEffect, useState, useRef, lazy, Suspense, memo, useCallback } from 'react';
import { SttTabs, SttCircularProgress } from '@stt-componentes/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: theme.spacing(1.5),
    },
}));

const Pesquisa = lazy(() => import('./pesquisa/Pesquisa'));
const Relatorio = lazy(() => import('./relatorio/Relatorio'));

const PesquisaSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Pesquisa {...props} />
        </Suspense>
    );
});

const RelatorioSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Relatorio {...props} />
        </Suspense>
    );
});

const Inicio = ({ strings, user }) => {
    const classes = useStyles();

    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');
    const stateRef = useRef();

    stateRef.current = abas;

    const adicionarAba = useCallback((aba) => {
        let dadosAbas = [...stateRef.current];

        let { idAba } = aba;
        // !some para evitar adição de aba repetida
        if (!dadosAbas.some((a) => a.idAba === idAba)) {
            dadosAbas.push(aba);
            setAbas(dadosAbas);
        }

        setTimeout(() => {
            setAbaAtiva(dadosAbas.findIndex((a) => a.idAba === idAba).toString());
        }, 120);
    }, []);

    const removerAba = (indice) => {
        setAbaAtiva('0');
        const i = indice[0];

        const dadosAbas = [...stateRef.current.slice(0, i), ...stateRef.current.slice(i + 1)];

        setTimeout(() => {
            setAbas(dadosAbas);
        }, 120);
    };

    useEffect(() => {
        let dadosAbas = [
            {
                titulo: 'Pesquisas', //strings.cadastroRedes,
                conteudo: PesquisaSuspense,
                key: 'PesquisaSuspense',
                permanente: true,
                adicionarAba,
            },
            // {
            //     titulo: 'Relatórios', //strings.cadastroRedes,
            //     conteudo: RelatorioSuspense,
            //     key: 'RelatorioSuspense',
            //     permanente: true,
            //     adicionarAba,
            // },
        ];

        setAbas(dadosAbas);
    }, [user]);

    return (
        <SttTabs
            abas={abas}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            preload={false}
        />
    );
};

export default Inicio;
