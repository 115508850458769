const stringsComuns = require('./comum');
module.exports = {
  Cabecalho: {},
  CadastroCidadeReferencia: {
    cadastroCidadeRef: 'Cadastro de Cidade Referência',
    cidadeReferencia: 'Cidade de referência',
    cidadesReferencias: 'Cidades referenciadas',
    confirmar: 'Vosê deseja realmente fazer esta alteração?',
    municipiosAdicionados: 'Municípios adicionados'
  },
  CadastroEquipes: {
    cidadeUf: 'Cidade/UF',
    cnes: 'CNES',
    codigoIne10Caracteres: 'O código INE deve conter exatamente 10 caracteres numéricos',
    confirmacaoAlteracaoEquipes: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroEquipes: 'Deseja enviar os dados preenchidos para cadastro?',
    ine: 'Código INE',
    instituicao: 'Instituição',
    nome: 'Nome',
    tituloPagina: 'Cadastro de Equipes de Saúde da Família (ESF)',
    uf: 'UF'
  },
  CadastroFuncionario: {
    alteracaoFuncionario: 'Alteração de dados do funcionário',
    aprovacaoCadastro: 'Aprovação de cadastro realizada com sucesso',
    cadastroFuncionario: 'Cadastro de funcionários',
    confirmacaoAlteracaoFuncionario: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroFuncionario: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoDeslogarUsuario: 'Deseja desconectar o funcionário de todos os dispositivos?',
    dadosContato: 'Dados de contato',
    dadosGerais: 'Dados gerais',
    dadosProfissionais: 'Dados profissionais',
    desejaAprovarCadastro: 'Deseja aprovar este cadastro e salvar os dados de alteração deste funcionário?',
    deslogarFuncionario: 'Deslogar funcionário',
    instrucaoAprovacaoAutoCadastro: `<span>- Cadastro aguardando triagem ou pendente;</span>
                                <span>- Utilize o botão "Aprovar" para aprovar este auto-cadastro e salvar as alterações do usuário;</span>
                                <span>- Você também pode utilizar o botão "Salvar" para alterar o usuário sem modificar a situação da solicitação de auto-cadastro.</span>`,
    profissaoInformada: 'Profissão informada',
    sucessoDeslogarUsuario: 'Funcionário deslogado com sucesso'
  },
  CadastroFuncionarioEquipe: {
    confirmacaoDelecaoEquipe: 'Você tem certeza que deseja excluir essa equipe de saúde?'
  },
  CadastroFuncionarioModalidade: {
    tipoVinculo: 'Tipo de vínculo'
  },
  CadastroFuncionarioPerfis: {
    confirmacaoDelecaoPerfil: 'Você realmente deseja desvincular este perfil do usuário?'
  },
  CadastroFuncionarioUsuario: {
    gerarNovaSenha: 'Cadastrar nova senha',
    gerarNovaSenhaAleatoria: 'Gerar nova senha aleatória',
    mensagemGerarNovaSenhaAleatoria: 'Você tem certeza que deseja gerar uma nova senha e enviar via email para este usuário?'
  },
  CadastroInstituicao: {
    bairro: 'Bairro',
    cep: 'CEP',
    cnes: 'CNES',
    cnpj: 'CNPJ',
    codinome: 'Codinome',
    confirmacaoAlteracaoInstituicao: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroInstituicao: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoDelecaoInstituicao: 'Você tem certeza que deseja excluir essa instituição? Quaisquer equipes de saúde associadas a ela também serão excluídas',
    confirmacaoDelecaoSetor: 'Você tem certeza de que deseja excluir este setor da instituição?',
    consultorioVirtualHabilitado: 'Consultório virtual habilitado?',
    logotipo: 'Logotipo',
    logotipoGrande: 'Você escolheu uma logotipo de resolução muito grande. Por favor, selecione outra imagem com resolução inferior a 400px de largura por 400px de altura.',
    logradouro: 'Logradouro',
    nome: 'Nome',
    remover: 'Remover',
    setorInstituicao: 'Setores da instituição',
    setorInstituicaoDuplicado: 'Já existe um setor desta instituição com esta descrição',
    tipoEstabelecimento: 'Tipo de estabelecimento',
    urlServidor: 'URL do servidor Web-RTC'
  },
  CadastroMateriaisSuporte: {
    alteracaoMateriaisSuporte: 'Alteração de materiais de apoio',
    anexos: 'Anexo',
    cadastroMateriaisSuporte: 'Cadastro de materiais de apoio',
    confirmacaoAlteracaoMateriaisSuporte: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroMateriaisSuporte: 'Deseja enviar os dados preenchidos para cadastro?',
    confirmacaoDelecao: 'Você realmente deseja excluir este perfil?',
    confirmacaoDelecaoModulo: 'Você realmente deseja excluir este módulo?',
    dadosGerais: 'Dados gerais',
    formatoArquivoInvalidoMateriaisSuporte: 'Formato de arquivo inválido. Somente PDF é aceito.',
    listamodulos: 'Lista de Módulos',
    listaperfis: 'Lista de Perfis',
    perfis: 'Perfis',
    selecioneTipoModulo: 'Por favor, selecione um tipo de módulo.'
  },
  CadastroMensagemGeral: {
    area: 'Área de interesse',
    assunto: 'Assunto',
    conteudo: 'Conteúdo',
    mensagemAtiva: 'A mensagem está ativa?'
  },
  CadastroMensagemOutros: {
    macrorregiao: 'Macrorregião'
  },
  CadastroMensagens: {
    confirmacaoAlteracaoMensagens: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroMensagens: 'Deseja enviar os dados preenchidos para cadastro?',
    outrasInformacoes: 'Outras informações',
    tituloPagina: 'Cadastro de mensagem',
    tituloPaginaAlteracao: 'Alteração de mensagem'
  },
  CadastroMotivosInvalidacaoExame: {
    confirmacaoCadastroMotivosInvalidacaoExame: 'Deseja enviar os dados preenchidos para cadastro?',
    modalidade: 'Modalidade',
    redeTelemedicina: 'Rede de Telemedicina',
    situacao: 'Situação',
    tituloPagina: 'Cadastro Motivos de Invalidação de Exame'
  },
  CadastroPaciente: {
    alertaAlteracao: 'Apenas dados cujo respectivo "checkbox" esteja marcado serão alterados.',
    confirmacaoAlteracaoPaciente: 'Confirma alteração do $ do paciente?',
    dadosCadastrais: 'Dados cadastrais',
    dataProcedimento: 'Data/Hora do procedimento',
    historicoProcedimentos: 'Histórico de procedimentos',
    tituloPagina: 'Alteração de pacientes'
  },
  CadastroPacs: {
    alteracaoPacs: 'Alteração de PACS',
    cadastroPacs: 'Cadastro de PACS',
    confirmacaoAlteracaoPacs: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroPacs: 'Deseja enviar os dados preenchidos para cadastro?'
  },
  CadastroPerfil: {
    alteracaoPerfil: 'Alteração de perfis',
    cadastroPerfil: 'Cadastro de perfis',
    confirmacaoAlteracaoPerfil: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroPerfil: 'Deseja enviar os dados preenchidos para cadastro?'
  },
  CadastroPermissao: {
    tituloPagina: 'Cadastro de permissões'
  },
  CadastroPonto: {
    alteracao: 'Alteração de dados do ponto',
    cadastro: 'Cadastro de ponto',
    confirmacaoAlteracao: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastro: 'Deseja enviar os dados preenchidos para cadastro?',
    dadosAtividade: 'Atividades',
    dadosContato: 'Dados de contato',
    dadosContato: 'Contatos',
    dadosExameUsuarios: 'Exames / Usuários vinculados',
    dadosGerais: 'Dados gerais',
    dadosTipo: 'Tipo de ponto',
    dadosVinculo: 'Vinculos',
    instituicaoDiferente: 'Você deve escolher uma instituição diferente da que está vinculada ao ponto original',
    senhaConfirmacaoSenhaIgual: 'A senha e a confirmação de senha devem ser iguais'
  },
  CadastroPontoDadosAtividade: {
    atividadesAdicionadas: 'Atividades adicionadas',
    confirmacaoDelecao: 'Você realmente deseja excluir esta atividade?',
    descricaoAtividade: 'Descrição da atividade'
  },
  CadastroPontoDadosContato: {
    confirmacaoDelecao: 'Você realmente deseja excluir este contato?',
    contatosAdicionados: 'Contatos adicionados',
    informeContato: 'Por favor, informe um contato válido.',
    selecioneTipoContato: 'Por favor, selecione um tipo de contato.',
    tipoContato: 'Tipo de contato'
  },
  CadastroPontoDadosGerais: {
    dataInclusao: 'Data de inclusão'
  },
  CadastroPontoDadosTipo: {
    aetitle: 'Aetitle',
    bridge: 'Bridge',
    confirmacaoSenha: 'Confirmação de senha'
  },
  CadastroPontoDadosVinculo: {
    secretario: 'Secretário de saúde'
  },
  CadastroPontoExamesVinculados: {
    exames30dias: 'Últimos 30 dias',
    exames90dias: 'Últimos 90 dias',
    exames180dias: 'Últimos 180 dias',
    examesVinculos: 'Exames vinculados ao ponto'
  },
  CadastroPontoMedicosVinculados: {
    solicitantes: 'Médicos Solicitantes vinculados a este ponto'
  },
  CadastroPontoTecnicosVinculados: {
    tecnicos: 'Técnicos vinculados a este ponto'
  },
  CadastroPontoVisualizadoresVinculados: {
    visualizadores: 'Visualizadores vinculados a este ponto'
  },
  CadastroRede: {
    confirmacaoAlteracaoRede: 'Deseja enviar os dados preenchidos para alteração?',
    confirmacaoCadastroRede: 'Deseja enviar os dados preenchidos para cadastro?',
    tituloPagina: 'Cadastro de redes de telemedicina',
    tituloPaginaAlteracao: 'Alteração de redes de telemedicina'
  },
  Checkin: {
    ano: 'Ano de nascimento',
    dadosDigitadosInvalidos: 'O(s) dado(s) digitados para validação estão incorretos. Por favor, digite as informações corretas para continuar.',
    dia: 'Dia de nascimento',
    erroGenericoValidacao: 'Não foi possível validar seus dados. Por favor tente novamente mais tarde.',
    erroValidacao: 'Erro de validação!',
    mes: 'Mês de nascimento',
    validar: 'Validar',
    validarDados: 'Para validarmos seu cadastro, por favor digite os dados solicitados abaixo.'
  },
  CnesCadsus: {
    cnesCadsus: 'CNES/CADSUS',
    dadosCADSUS: 'Dados no CADSUS',
    dadosCNES: 'Dados no CNES'
  },
  Comum: {
    acompanharFacebook: 'Acompanhar facebook',
    aguardandoTriagem: 'Aguardando triagem',
    ativar: 'Ativar',
    bloquear: 'Bloquear',
    cadastroAprovado: 'Cadastro aprovado',
    cadastroPendente: 'Cadastro pendente',
    cadastroRejeitado: 'Cadastro rejeitado',
    classificada: 'Classificada',
    dataHoraSolicitacao: 'Data e hora da solicitação',
    desativar: 'Desativar',
    desbloquear: 'Desbloquear',
    legendaInstituicaoVinculoCadsus: 'Vínculos com instituição de origem DATASUS',
    legendaInstituicaoVinculoStt: 'Instituições informadas manualmente',
    loginCaracteresAlfanumericos: 'Somente caracteres alfanuméricos são aceitos',
    motivoRecusa: 'Motivo de recusa',
    naoClassificada: 'Não classificada',
    naoRespondeu: 'Não respondeu',
    numeroConselhoTrabalhoTamanho: 'O número do conselho de trabalho deve conter no máximo 20 caracteres',
    numeroRqe: 'Número RQE',
    possuiConselhoTrabalho: 'Possui conselho de trabalho?',
    profissaoInformada: 'Profissão informada',
    rqe: 'RQE - Registro de Qualificação de Especialidade',
    rqeJaAdicionado: 'RQE já adicionado',
    timeoutToken: 'Timeout (em minutos) para tokens de usuário',
    tipoOcupacao: 'Tipo de ocupação',
    todas: 'Todas',
    triagemAutoCadastro: 'Triagem de auto-cadastro',
    trocaPonto: 'Troca de ponto'
  },
  DadosContato: {},
  DadosContatoTriagemAutoCadastro: {},
  DadosGerais: {
    atividade: stringsComuns.atividade,
    dadosEncontradosCadsus: 'Foram encontrados dados do funcionário no CADSUS pelo CPF informado. Você deseja continuar o cadastro a partir desses dados?',
    divulgacaoAutorizada: 'Divulgação Autorizada',
    funcionarioJaExiste: 'Já existe um funcionário cadastrado para o CPF informado. Por favor, utilize o menu de pesquisa para realizar visualização ou alteração dos dados.',
    verificarCpf: 'Verificar CPF'
  },
  DadosGeraisTriagemAutoCadastro: {},
  DadosProfissionais: {
    numeroRqe: 'Número RQE',
    rqe: 'RQE - Registro de Qualificação de Especialidade',
    rqeJaAdicionado: 'RQE já adicionado'
  },
  DadosProfissionaisTriagemAutoCadastro: {},
  DetalhesFuncionario: {
    dadosGerais: 'Dados gerais',
    visualizacaoFuncionario: 'Visualização de funcionário'
  },
  DetalhesSolicitacaoAutoCadastro: {
    aprovacaoCadastro: 'Aprovação de cadastro realizada com sucesso',
    aprovarAutoCadastro: 'Aprovar auto-cadastro',
    confirmacaoTornarPendente: 'Tem certeza que deseja tornar esta solicitação pendente?',
    desejaAprovarAutoCadastro: 'Deseja aprovar este auto-cadastro?',
    irParaCadastro: 'Ir para cadastro',
    mensagemAtualizarCadastroPendente: 'Atualizando situação da solicitação',
    solicitacaoPendenteAtualizada: 'A situação da solicitação foi atualizada para pendente',
    tornarPendente: 'Tornar pendente',
    triarAutoCadastro: 'Triar auto-cadastro'
  },
  Externo: {},
  FormMateriaisSuporte: {
    dataCriacaoAte: 'Data final',
    dataCriacaoDe: 'Data inicial',
    nome: 'Nome'
  },
  FormPesquisa: {
    ine: 'Código INE',
    instituicao: 'Instituição',
    nomeLogin: 'Nome/login',
    numeroRqe: 'Número RQE',
    pesquisaFuncionarioNovoCadastro: 'Detectamos que você realizou uma pesquisa por CPF que não obteve nenhum resultado. Deseja começar um novo cadastro a partir desse CPF?',
    uf: 'UF'
  },
  FormPesquisaMensagens: {
    area: 'Área de interesse',
    assunto: 'Assunto',
    cidade: 'Cidade',
    equipe: 'Equipe',
    instituicao: 'Instituição',
    macrorregiao: 'Macrorregião',
    situacao: 'Situação'
  },
  FormPesquisaPacs: {
    url_base: 'URL base',
    versao_dcm4chee: 'Versão dcm4chee'
  },
  ///PESQUISA DE PERFIS
  FormPesquisaPerfis: {},
  FormPesquisaRede: {
    nenhumRegistroEncontrado: 'Nenhum registro encontrado',
    visualizar: 'Visualizar'
  },
  FormTriagemAutoCadastro: {
    cadastroAprovado: 'Cadastro aprovado',
    cadastroPendente: 'Cadastro pendente'
  },
  FormularioTriagemAutoCadastro: {
    verificandoLogin: 'Verificando login'
  },
  ListaPermissoesModulo: {
    confirmacaoCadastro: 'Você tem certeza que deseja cadastrar as permissões para o perfil escolhido?',
    mensagemConfirmacaoDesselecao: 'Desmarcando essa opção, todas as permissões desse perfil para o módulo em questão serão desmarcadas. Você deseja prosseguir?'
  },
  ModalAdicionarEquipe: {
    adicionarEquipe: 'Adicionar equipe'
  },
  ModalAdicionarModalidade: {
    adicionarModalidade: 'Adicionar modalidade',
    confirmarExclusao: 'Você confirma a exclusão desta modalidade?'
  },
  ModalAdicionarSetor: {
    adicionarSetor: 'Adicionar setor'
  },
  ModalAdicionarUsuario: {
    adicionarUsuario: 'Adicionar usuário',
    alterarUsuario: 'Alterar usuário'
  },
  ModalAlteracaoSenha: {
    alteracaoSenha: 'Alteração de senha',
    confirmacaoSenha: 'Confirmação de senha',
    novaSenha: 'Nova senha',
    novaSenhaConfirmacaoCpf: 'A senha não pode conter o CPF',
    novaSenhaConfirmacaoDataNascimento: 'A senha não pode conter sua data de nascimento',
    novaSenhaConfirmacaoEmail: 'A senha não pode conter o Email',
    novaSenhaConfirmacaoLogin: 'A senha não pode conter o Login de usuário',
    novaSenhaConfirmacaoNome: 'A senha não pode conter o nome',
    novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais',
    senhaAoMenosUmaMaiuscula: 'A senha deve conter pelo menos uma letra maiúscula.',
    senhaAoMenosUmaMinuscula: 'A senha deve conter pelo menos uma letra minúscula.',
    senhaAoMenosUmCaracter: 'A senha deve conter pelo menos um caractere especial.',
    senhaAoMenosUmNumero: 'A senha deve conter pelo menos um número.',
    senhaMinimo9Caracteres: 'A senha deve ter no mínimo 8 caracteres.',
    senhaSemEspacos: 'A senha não pode conter espaços.'
  },
  OperacoesPonto: {
    confirmarOperacao: 'Você confirma esta operação sobre o ponto de telediagnóstico?',
    label: operacao => `Motivo para ${operacao} o ponto:`
  },
  PesquisaEquipes: {
    cidadeUf: 'Cidade/UF',
    cnes: 'CNES',
    ine: 'Código INE',
    instituicao: 'Instituição',
    nome: 'Nome',
    tituloPagina: 'Pesquisa de Equipes de Saúde da Família (ESF)',
    uf: 'UF'
  },
  PesquisaFuncionario: {
    numeroRqe: 'Número RQE',
    tituloPagina: 'Pesquisa de funcionários',
    usuariosNomeEmailVinculo: 'Usuários (e-mail e vínculo)'
  },
  PesquisaInstituicao: {
    tituloPagina: 'Pesquisa de instituições',
    consultorioVirtualHabilitado: 'Consultório virtual habilitado?'
  },
  TriagemAcessoInstituicao: {
    confirmarAprovar: 'Você confirma a aprovação dessa solicitação de acesso?',
    confirmarRejeitar: 'Você confirma a rejeição dessa solicitação de acesso?',
    tituloPagina: 'Triagem de acesso a instituições',
    funcionarioSolicitante: 'Funcionário solicitante',
    instituicaoSolicitada: 'Instituição solicitada',
    instituicao: 'Instituição',
    dataSolicitacao: 'Data da solicitação',
    situacao: 'Situação',
    pendente: 'Pendente',
    aprovado: 'Aprovado',
    rejeitado: 'Rejeitado',
    nomeSolicitante: 'Nome do solicitante',
    rqe: 'RQE',
    datHoraSolicitacao: 'Data/Hora da solicitação',
    situacaoSolicitacao: 'Situação da solicitação',
    motivoSolicitacao: 'Motivo da solicitação',
    conselhoTrabalho: 'Conselho de trabalho',
    funcionarioAvaliador: 'Funcionario avaliador',
    dataAvaliacao: 'Data da avaliação',
    motivoRejeicao: 'Motivo da rejeição',
    dadosRejeicao: 'Dados da Rejeição',
    aprovar: 'Aprovar',
    rejeitar: 'Rejeitar',
    rejeitarSolicitacaoAcesso: 'Rejeitar solicitação de acesso a instituição',
    dadosSolicitacao: 'Dados da solicitação',
    instituicoesCadastradas: 'Instituições cadastradas'
  },
  PesquisaInvalidacaoExame: {
    modalidade: 'Modalidade',
    redeTelemedicina: 'Rede de Telemedicina',
    situacao: 'Situação',
    tituloPagina: 'Motivos de Invalidação de Exame'
  },
  PesquisaLogFuncionario: {
    categoriaStt1: 'Categoria',
    categoriaStt2: 'Categoria STT2',
    data: 'Data',
    dataAte: 'Até',
    dataDe: 'Desde',
    evento: 'Evento',
    funcionario: 'Funcionário',
    tituloPagina: 'Pesquisa de Log Funcionário',
    url: 'Url'
  },
  PesquisaMateriaisSuporte: {
    tituloPagina: 'Pesquisa de materiais de apoio'
  },
  PesquisaMensagens: {
    area: 'Área de interesse',
    assunto: 'Assunto',
    mensagem: 'Mensagem',
    opcoes: 'Opções',
    situacao: 'Situação',
    tituloPagina: 'Pesquisa de mensagens'
  },
  PesquisaPaciente: {
    tituloPagina: 'Pesquisa de pacientes'
  },
  PesquisaPacs: {
    tituloPagina: 'Pesquisa de PACS'
  },
  PesquisaPerfil: {
    confirmacaoExcluirPerfil: 'Deseja mesmo excluir esse perfil?',
    tituloPagina: 'Pesquisa de perfis'
  },
  PesquisaPontos: {
    dataCriacaoAte: 'Criação - até',
    dataCriacaoDe: 'Criação - de',
    tituloPagina: 'Pesquisa de pontos'
  },
  PesquisaRede: {
    tituloPagina: 'Pesquisa de redes'
  },
  RejeitarAutoCadastro: {
    confirmacaoRejeitarSolicitacao: 'Tem certeza que deseja rejeitar essa solicitação de auto-cadastro?',
    erroRejeitarCadastro: 'Ocorreu um erro ao rejeitar a solicitação. Tente novamente mais tarde',
    motivoInvalido: 'O motivo deve ter pelo menos 10 caracteres',
    titulo: 'Rejeitar auto-cadastro'
  },
  SolicitacaoAviso: {
    solicitacao: 'Solicitação de cadastro',
    solicitacaoPaciente: 'Solicitação de cadastro de paciente',
    texto1: instanciaNome => `Seja bem-vindo(a) ao ${instanciaNome}, o STT! Antes de prosseguir com o seu cadastro, saiba que:`,
    texto1Paciente: 'Preencha corretamente todos os campos do formulário de cadastro.',
    texto2: 'Este cadastro dará acesso aos serviços do STT.',
    texto3: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Após informar o seu e-mail EBSERH, o seu CPF será conferido e seus dados serão importados do CadSUS. Você deverá preencher os campos solicitados para finalizar sua solicitação de cadastro.' : 'Após informar o seu CPF, os seus dados serão importados do CadSUS. Você deverá preencher os campos solicitados e criar a sua senha de acesso ao STT.',
    texto4: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Ao concluir o preenchimento dos dados e clicar no botão "Salvar", você estará automaticamente habilitado para fazer o acesso inicial ao sistema. A habilitação aos demais serviços dependerá da validação da nossa equipe.' : 'Ao concluir o preenchimento dos dados e clicar no botão "Salvar", você estará automaticamente habilitado para acessar, exclusivamente, o serviço de Tele-educação. A habilitação aos demais serviços dependerá da validação da nossa equipe.',
    texto4Paciente: (instanciaNome, exibirPesquisa) => `Esse processo garante que suas participações no ${instanciaNome} sejam registradas como atividades de ${!exibirPesquisa ? 'educação permanente' : 'pesquisa'}!`,
    texto5: (instanciaNome, basename) => instanciaNome === 'ebserh' || basename === 'ebserh' ? 'Mais informações serão enviadas para o seu e-mail EBSERH, que deve ser de uso individual.' : 'Mais informações serão enviadas para o e-mail cadastrado, que deve ser um e-mail de uso pessoal e individual.',
    textoAcessoCadsus: 'Seus dados serão buscados no sistema do CADSUS e serão registrados em nosso sistema de forma sigilosa.'
  },
  SolicitacaoBuscaCpf: {
    buscandoDadosCpf: 'Aguarde. Buscando dados do seu CPF...',
    mensagemNaoEncontrouPaciente: 'Não encontramos suas informações em nossa base de dados e nem no CADSUS. Por favor, completo seu cadastro manualmente.',
    mensagemUsuarioJaExisteEbserh: 'Este e-mail já possui um usuário cadastrado. Por favor, tente realizar o login para continuar.',
    irParaLogin: 'Ir para login'
  },
  SolicitacaoComplemento: {
    celularWhatsapp: 'O telefone celular informado é um número de whatsapp?',
    telefoneComercial: 'Telefone comercial'
  },
  SolicitacaoConfirmacaoMensagem: {
    okEntendi: 'Ok, entendi. Quero me cadastrar!'
  },
  SolicitacaoContato: {
    celularWhatsapp: 'O telefone celular informado é um número de whatsapp?',
    telefoneComercial: 'Telefone comercial'
  },
  SolicitacaoDadosProfissionais: {
    numeroConselho: 'Número do conselho',
    possuiConselho: 'Possui conselho de trabalho?',
    tipoConselho: 'Tipo de conselho'
  },
  SolicitacaoEquipeFormIndex: {
    adicionarEquipe: 'Adicionar equipe de saúde'
  },
  SolicitacaoEquipeFormOpcoes: {
    ine: 'Código INE',
    nomeEquipe: 'Nome da equipe de saúde',
    novaEsf: 'Adicionar nova ESF'
  },
  SolicitacaoEquipeIndex: {
    confirmarExclusao: 'Você confirma a exclusão desta equipe de saúde?',
    ine: 'Código INE',
    nenhumaEquipe: 'Nenhuma equipe de saúde adicionada'
  },
  SolicitacaoIndex: {
    chackList8Caracteres: 'Pelo menos 8 caracteres.',
    chackListAlfabetico: 'Caractere alfabético.',
    chackListNumerico: 'Caractere numérico.',
    chackListEspecial: 'Caractere especial.',
    chackListMinuscula: 'Letra minúscula.',
    chackListMaiuscula: 'Letra maiúscula.',
    alteracaoSenha: 'Alteração de senha',
    avisoPesquisaCadsus: 'Seus dados foram importados do Cartão Nacional do SUS e DataSUS/CNES. Preencha os dados em branco.',
    confirmacaoSenha: 'Confirmação de senha',
    confirmacaoSenha: 'Confirmação de senha',
    crieSuaSenha: 'Crie sua senha',
    dadosProfissionais: 'Dados profissionais',
    erroSalvar: 'Não foi possível salvar os dados da solicitação de cadastro.',
    escolhaSenhaConfirmacaoSenha: 'Preencha sua senha e confirmação de senha',
    escolhaUfCidade: 'Confirme seu estado e município',
    identificacao: 'Identificação',
    instrucaoSenhaCaracterEspecial: '- Ao menos um caractere especial',
    instrucaoSenhaLetraMaiuscula: '- Ao menos uma letra em maiúsculo;',
    instrucaoSenhaMinimo8Digitos: '- Ao menos oito dígitos;',
    instrucaoSenhaNumero: '- Ao menos um número;',
    instrucaoSenhaTitulo: 'A sua senha deve possuir:',
    maisMedicosResidencia: 'Mais médicos / PROVAB / Residência',
    mensagemFaltaVinculoSolicitacao: 'Não identificamos, no seu cadastro, vínculo de atuação profissional na área da saúde. Com isso, você terá acesso apenas ao serviço de Tele-educação (webconferências e minicursos)',
    mensagemSolicitacaoCadastroAzureAd: 'A seguir, você deverá vincular seu e-mail da EBSERH (@ebserh.gov.br) para finalizar o seu cadastro.',
    novaSenha: 'Nova senha',
    novaSenhaConfirmacaoCpf: 'A senha não pode conter o CPF',
    novaSenhaConfirmacaoDataNascimento: 'A senha não pode conter sua data de nascimento',
    novaSenhaConfirmacaoEmail: 'A senha não pode conter o Email',
    novaSenhaConfirmacaoLogin: 'A senha não pode conter o Login de usuário',
    novaSenhaConfirmacaoNome: 'A senha não pode conter o nome',
    novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais.',
    novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais',
    novoCadastro: 'Solicitar novo cadastro',
    novoCadastroPaciente: 'Solicitar novo cadastro de paciente',
    numeroConselhoInvalido: 'Número de conselho inválido',
    opcaoNaoAtuoProfissionalmente: 'Não atuo profissionalmente na área da saúde',
    opcaoVoltarPreencherVinculo: 'Voltar e preencher o vínculo',
    profissaoInvalida: 'Profissão inválida',
    senhaAoMenosUmaMaiuscula: 'A senha deve conter pelo menos uma letra maiúscula.',
    senhaAoMenosUmaMinuscula: 'A senha deve conter pelo menos uma letra minúscula.',
    senhaAoMenosUmCaracter: 'A senha deve conter pelo menos um caractere especial.',
    senhaAoMenosUmNumero: 'A senha deve conter pelo menos um número.',
    senhaMinimo9Caracteres: 'A senha deve ter no mínimo 8 caracteres.',
    senhaSemEspacos: 'A senha não pode conter espaços.',
    sucessoCadastroPaciente: 'Dados da solicitação de cadastro salvos com sucesso.',
    termosAutorizacoes: 'Termos e autorizações',
    vinculos: 'Vínculos',
    erroGeralAutenticacaoExterna: 'Ocorreu um erro na autenticação da sua conta. Por favor, tente novamente mais tarde.'
  },
  SolicitacaoInstituicaoFormIndex: {
    adicionarInstituicao: 'Adicionar instituição'
  },
  SolicitacaoInstituicaoFormPesquisa: {},
  SolicitacaoInstituicaoIndex: {
    confirmarExclusao: 'Você confirma a exclusão desta instituição?',
    nenhumaInstituicao: 'Nenhuma instituição adicionada'
  },
  SolicitacaoInstituicaoTabelaInstituicoes: {},
  SolicitacaoParticipacao: {
    confirmarExclusao: 'Você confirma a exclusão desta participação?',
    nenhumParticipacao: 'Nenhuma participação adicionada',
    participacao: 'Participação'
  },
  SolicitacaoParticipacaoFormIndex: {},
  SolicitacaoParticipacaoFormOpcoes: {
    participacaoConflitante: 'Não podem existir períodos no mesmo intervalo de tempo dos já existentes'
  },
  SolicitacaoTermoIndex: {
    divulgacaoEmail: 'Aceito receber divulgação por email.',
    infoDivulgacao: `As divulgações enviadas semanalmente informam sobre a agenda de atividades de Tele-educação e as novidades dos serviços de Telediagnóstico e Teleconsultoria.
                                                                                                Obs.: O Telessaúde UFSC reserva-se ao direito de enviar comunicados importantes sobre o uso e a oferta dos serviços sem autorização de seus usuários.`,
    leiaTermo: 'Leia o Termo aqui'
  },
  TermoConsentimento: {
    erroSalvarTcle: 'Não foi possível salvar o termo de consentimento. Por favor, tente novamente mais tarde.',
    textoTermo: (cpf, nome) => `<b>TERMO DE CONCORDÂNCIA / AUTORIZAÇÃO TERMO DE CONCORDÂNCIA / AUTORIZAÇÃO PARA SERVIÇOS DE TELEMEDICINA</b>
                                <br/>
                                <br/>
                                <b>ATENÇÃO:</b> A leitura, compreensão e concordância do presente termo é indispensável para o acesso aos serviços de telemedicina oferecidos pelo 
                                                <b>Sistema Catarinense de Telemedicina e Telessaúde</b>. Em caso de dúvida, quaisquer que sejam, peça explicações ao profissional de saúde que 
                                                lhe prestou atendimento. Não tenha pressa, o importante é a sua ciência que os serviços de Telemedicina somente são realizados após sua plena e livre consciência.
                                <br/>
                                <br/>
                                Eu, ${nome}, inscrito(a) no CPF sob o nº ${cpf}, declaro que fui orientado(a) e esclarecido(a) pelo presente Termo. Por concordar com o(s) serviço(s) 
                                                de telemedicina por meios digitais seguros, ratifico a minha ciência e concordância para as seguintes disposições:
                                <br/>
                                A telemedicina é caracterizada como forma de um serviço médico mediado por tecnologias de comunicação, de acordo com o disposto na Resolução 
                                                nº 2.314, de 20 de abril de 2022, do Conselho Federal de Medicina; O presente Termo pode ser atualizado periodicamente em decorrência de mudanças 
                                                legais e éticas, sendo que serei comunicado por meio de correio eletrônico registrado em meu cadastro para nova ratificação de concordância.
                                <br/>
                                <br/>
                                <b>DESTE MODO:</b>
                                <br/>
                                Autorizo a(s) gravação(ões) da(s) consulta(s), a critério do médico e com minha anuência, para que áudio e vídeo sejam integrantes das minhas informações, 
                                                com garantia de guarda, sigilo e confidencialidade, conforme resoluções do CFM, do Código de Ética Médica e da Lei Geral de Proteção de Dados (Lei nº 13.709/2018); 
                                                Autorizo que um profissional da saúde, previamente capacitado e treinado realize tanto a coleta de dados quanto os exames. Autorizo o armazenamento e acesso aos meus exames, 
                                                bem como aos demais dados clínicos e pessoais; Autorizo expressamente ao(s) médico(s) a análise de toda e qualquer documentação médica referente ao meu caso, sem a minha 
                                                presença física no consultório, para auxiliar no diagnóstico; Autorizo a utilização dos meus dados de saúde, sem minha identificação (anonimizados ou pseudoanonimizados), 
                                                para realização de pesquisa e inovação na área de inteligência artificial, que permitirá o desenvolvimento de novos métodos de identificação de doenças e novos protocolos para tratamento de comorbidades; 
                                <br/>
                                Fui informado que todos os dados clínicos e pessoais que são enviados na plataforma, encontram-se em ambiente seguro e o acesso dos mesmos só poderão ser obtidos pelo meu médico assistente da UBS e o médico 
                                                responsável por emitir o laudo. Na hipótese de realização de exames, o acesso a exame/laudo sem as imagens poderá ser feito por mim, ou a quem eu delegar a chave senha, gerada no momento do exame. 
                                                Autorizo o Ministério da Saúde, as Secretarias de Saúde e Universidade Federal de Santa Catarina a fazerem uso de informações relativas aos meus dados e imagens, desde que ASSEGURADO O ANONIMATO. 
                                                Este consentimento pode ser revogado, sem qualquer ônus ou prejuízo à minha pessoa, a meu pedido ou solicitação, desde que a revogação ocorra antes da publicação. 
                                <br/>
                                O presente Termo poderá ser rescindido por mim a qualquer momento, sendo que, por meio dele, manifesto estar ciente e que concordo com as condições estabelecidas.
                                <br/>
                                <br/>
                                Declaro que li e entendi o presente Termo. Declaro que li e compreendi o presente Termo.
                                <br/>
                                <b>Nome do participante:</b> ${nome}
                                <br/>
                                <b>CPF do participante:</b> ${cpf}`,
    textoTermoTeleatendimento: (cpf, nome) => `Termo de Consentimento Livre e Esclarecido para Teleconsulta.
                                <br/>
                                Por este instrumento particular, informo que me foram explicadas as características da Teleconsulta, as minhas responsabilidades e as do (a) médico(a) que me atenderá, e que eu concordo com o que me foi explicado e entendi minhas responsabilidades antes de iniciar a Teleconsulta. Recebi as seguintes informações antes da consulta:
                                <br/>
                                 <ul>
                                    <li>A Teleconsulta é considerada como prática médica ética em caráter temporário e extraordinário, enquanto durar a emergência de saúde pública de importância internacional (ESPII) (artigo 3º da Lei nº 13.979/2020 e artigo 37º do Código de Ética Médica);</li>
                                    <li>A plataforma digital utilizada na minha Teleconsulta segue as normas HIPAA compliance para assegurar a proteção e o sigilo das informações contra vazamento de dados (garante que a minha teleconsulta é segura e que os dados não serão vazados na internet);</li>
                                    <li>Caso eu concorde, o (a) médico (a) poderá fazer uma gravação durante o atendimento, com o objetivo exclusivo de registro em prontuário de atendimento médico. Caso não seja feita a gravação, o (a) médico (a) fará o registro no seu prontuário médico;</li>
                                    <li>Pelo sigilo do atendimento médico, não poderei fazer qualquer gravação sem autorização explícita do (a) médico (a) que prestou atendimento;</li>
                                    <li>Durante a Teleconsulta, devo estar em local reservado (local separado, silencioso e que permita que eu possa me concentrar na consulta) sem a presença de outras pessoas, exceto as que o(a) médico(a) considerar necessárias para o processo do atendimento;</li>
                                    <li>Todas as informações estão garantidas pelo sigilo médico, e como tal, devo garantir que permaneçam confidenciais ( tanto o médico quanto eu devemos garantir que o conteúdo da consulta permaneça em sigilo);</li>
                                    <li>Todas as informações relatadas por mim são verdadeira, e eu compreendo que    devo relatar ao médico as informações claras, pois elas são fundamentais para a investigação médica;</li>
                                    <li>Caso o (a) médico (a) considere necessário, poderá solicitar meu comparecimento presencial para a realização de exame físico ou exames complementares; </li>
                                </ul> 
                                <br/>
                                Confirmo que li, compreendi e concordo com todos os termos deste Termo de Ciência e Consentimento Livre e Esclarecido para Teleconsulta,
                                <br/>
                                <b>Nome do paciente:</b> ${nome}
                                <br/>
                                <b>CPF do paciente:</b> ${cpf}
                                <br/>`
  },
  TriagemAutoCadastro: {},
  TriagemAutoCadastro: {
    tituloPagina: 'Triagem de auto-cadastro'
  }
};