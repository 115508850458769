import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { logoSecretariaSaude, logoSus } from './imagem/logoBase64';
import { tableToJson } from './HTMLStringUtil';
const gerarDocJSPDF = (columns, rows) => {
  if (!columns || columns.length === 0 || !rows || rows.length === 0) {
    console.error('Tabela não encontrada para exportação.');
    return;
  }
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4'
  });
  const totalPagesExp = '{total_pages_count_string}';
  doc.setFont('helvetica');
  doc.setFontSize(9);
  doc.setDrawColor(50, 54, 57);
  const larguraPagina = doc.internal.pageSize.getWidth();
  const alturaPagina = doc.internal.pageSize.getHeight();
  const margem = 20;
  const larguraUtil = larguraPagina - margem * 2;
  const colunasPorPagina = 6;
  const numeroDePaginas = Math.ceil(columns.length / colunasPorPagina);
  for (let paginaAtual = 0; paginaAtual < numeroDePaginas; paginaAtual++) {
    if (paginaAtual > 0) {
      doc.addPage(); // gera uma nova página
    }
    const startCol = paginaAtual * colunasPorPagina;
    const endCol = Math.min(startCol + colunasPorPagina, columns.length);
    const selectedColumns = columns.slice(startCol, endCol);
    const tableColumn = ['#', ...selectedColumns];
    const tableRows = rows.map((row, index) => [index + 1, ...selectedColumns.map((_, j) => row[j] ? row[j] : '')]);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 170,
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',
        minCellHeight: 10
      },
      columnStyles: Object.assign({
        0: {
          cellWidth: 30
        }
      }, selectedColumns.reduce((acc, _, index) => {
        acc[index + 1] = {
          cellWidth: Math.max(70, (larguraUtil - 30) / selectedColumns.length)
        }; // número total de páginas necessárias
        return acc;
      }, {})),
      margin: {
        top: 170,
        right: margem,
        bottom: 40,
        left: margem
      },
      didDrawPage: data => {
        // Paginação
        let textoRodape = 'Página ' + data.pageNumber;
        if (typeof doc.putTotalPages === 'function') {
          textoRodape = textoRodape + ' de ' + totalPagesExp;
        }
        doc.text(textoRodape, larguraPagina - margem - 100, alturaPagina - 10);
      },
      didParseCell: data => {
        if (data.cell.section === 'head') {
          data.cell.styles.fillColor = [7, 77, 128];
        }
      }
    });
  }
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }
  return doc;
};
const exportarFrontend = (columns, rows) => {
  const doc = gerarDocJSPDF(columns, rows);
  doc.save('tabela-de-dados.pdf');
};
const exportarBackend = htmlString => {
  const dom = new JSDOM(htmlString);
  const [columns, rows] = tableToJson(dom);
  const doc = gerarDocJSPDF(columns, rows);
  return doc.output('arraybuffer');
};
export default {
  exportarFrontend,
  exportarBackend
};