import { useState, useCallback } from 'react';
import axios from 'axios';
import { MSGBuilder, MSGCode } from '@stt-analise/util';
import { Cache } from '@stt-componentes/cache';

const post = async (payload) => {
    const BACKEND_URL = global.gConfig.url_base_graphql;
    const response = await axios.post(`${BACKEND_URL}/inicio/relatorio`, payload, {
        method: 'POST',
        headers: { Authorization: `Bearer ${Cache.getAccessToken()}`, 'Content-Type': 'application/json' },
    });

    return response.data;
};

export default function usePostRelatorio() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const reqPostRelatorio = useCallback(async ({ payload, triggerAlert, strings }) => {
        try {
            setLoading(true);
            const data = await post(payload);
            console.log('reqPostRelatorio', data);
            window.open(data, '_blank');
            setLoading(true);
        } catch (err) {
            console.error(err);
            const msgAlert = new MSGBuilder(MSGCode.ERR001, strings).buildMsgAlert();
            triggerAlert(msgAlert);
            setError(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return { reqPostRelatorio, error, loading };
}
