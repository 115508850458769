import FormatDate from "../format/FormatDate";
import FiltroConstants from "../constants/FiltroConstants";
/**
 * Normalizar filtros para enviar ao backend.
 * - Retirar os filtros com valores vazios;
 * - Normalizar campos.
 */
export const normalizeFiltros = filtros => filtros.filter(filtro => filtro.valor != undefined && filtro.valor != '' && filtro.atributo != '').filter(filtro => {
  switch (filtro.tipoComponente) {
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
      if (typeof filtro.valor === 'function') {
        const [startDate, finalDate] = filtro.valor();
        return startDate !== null && finalDate !== null;
      }
    default:
      return true;
  }
}).map(filtro => {
  let nValor;
  switch (filtro.tipoComponente) {
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
      if (typeof filtro.valor === 'function') {
        const [startDate, finalDate] = filtro.valor();
        nValor = `${startDate && startDate.getTime()}${FiltroConstants.SPLIT_VALUES}${finalDate && finalDate.getTime()}`;
      } else {
        nValor = filtro.valor;
      }
      break;
    default:
      nValor = filtro.valor;
      break;
  }
  return Object.assign(Object.assign({}, filtro), {
    atributo: filtro.atributo,
    tipoComponente: filtro.tipoComponente || FiltroConstants.TIPO_FILTRO.EQUAL,
    valor: nValor
  });
});
/**
 * Normalizar filtros para o frontend:
 * - Carregados de um componente frontend;
 * - Carregados do backend.
 */
export const normalizeFiltrosUI = filtros => filtros.filter(filtro => {
  switch (filtro.tipoComponente) {
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
      if (typeof filtro.valor === 'function') {
        const [startDate, finalDate] = filtro.valor();
        return startDate !== null && finalDate !== null;
      }
    default:
      return true;
  }
}).map(filtro => {
  let nValor;
  switch (filtro.tipoComponente) {
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
      if (typeof filtro.valor === 'function') {
        const [startDate, finalDate] = filtro.valor();
        nValor = `${startDate && startDate.getTime()}${FiltroConstants.SPLIT_VALUES}${finalDate && finalDate.getTime()}`;
      } else {
        nValor = filtro.valor;
      }
      break;
    default:
      nValor = filtro.valor;
      break;
  }
  return Object.assign(Object.assign({}, filtro), {
    atributo: filtro.atributo,
    tipoComponente: filtro.tipoComponente || FiltroConstants.TIPO_FILTRO.EQUAL,
    valor: nValor
  });
});
export const normalizeValorEmail = _ref => {
  let {
    valor,
    tipoComponente
  } = _ref;
  let nValor = valor;
  console.log(tipoComponente);
  switch (tipoComponente) {
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS:
    case FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIME:
      const [bTimestamp, eTimestamp] = valor.split(FiltroConstants.SPLIT_VALUES);
      nValor = `${FormatDate.formatDate(Number(bTimestamp), 'L')} a ${FormatDate.formatDate(Number(eTimestamp), 'L')}`;
      break;
    case FiltroConstants.TIPO_FILTRO.EQUAL:
    case FiltroConstants.TIPO_FILTRO.LIKE:
      nValor = `igual a '${valor}'`;
      break;
    case FiltroConstants.TIPO_FILTRO.NOT_EQUAL:
    case FiltroConstants.TIPO_FILTRO.NOT_LIKE:
      nValor = `diferente de '${valor}'`;
      break;
    case FiltroConstants.TIPO_FILTRO.IN:
    case FiltroConstants.TIPO_FILTRO.IN_SELECT:
      nValor = `contém [${valor}]`;
      break;
    case FiltroConstants.TIPO_FILTRO.NOT_IN:
      nValor = `não contém [${valor}]`;
      break;
    default:
      break;
  }
  return nValor;
};
;
export const normalizeFiltrosAcessoExternoParaDruid = (filtros, datesFields) => filtros.map(_ref2 => {
  let {
    atributo,
    valor
  } = _ref2;
  // 1 - Se é um atributo do tipo Date
  if (datesFields.includes(atributo)) {
    let [start, end] = valor;
    if (start) start = FormatDate.formatDataToTimestamp(start);
    if (end) end = FormatDate.formatDataToTimestamp(end);
    return {
      atributo,
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_NUMBER,
      valor: `${start}${FiltroConstants.SPLIT_VALUES}${end}`
    };
  }
  // Para os demais atributos quando for array, transformar em string
  if (Array.isArray(valor)) {
    const nValor = valor.join(FiltroConstants.SPLIT_VALUES);
    return {
      atributo,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN,
      valor: nValor
    };
  }
  return {
    atributo,
    tipoComponente: FiltroConstants.TIPO_FILTRO.EQUAL,
    valor
  };
});